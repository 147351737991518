<template>
  <div class="position-add-edit-box">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form class="form" @submit="onSubmit">
      <div class="filed-box">
        <van-field
          v-model="positionForm.name"
          name="职位名称"
          label="职位名称"
          required
          placeholder="请输入职位名称"
          :rules="[{ required: true }]"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.qualificationsName"
          name="学历要求"
          label="学历要求"
          required
          readonly
          placeholder="请选择学历要求"
          :rules="[{ required: true }]"
          @click="qualificationsTypePicker = true"
        >
          <template #button>
            <van-icon v-if="qualificationsTypePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="qualificationsTypePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.qualificationsType"
            @cancel="qualificationsTypePicker = false"
            @confirm="qualificationsTypeConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.workExperienceName"
          name="经验要求"
          label="经验要求"
          required
          readonly
          placeholder="请选择经验要求"
          :rules="[{ required: true }]"
          @click="workExperiencePicker = true"
        >
          <template #button>
            <van-icon v-if="workExperiencePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="workExperiencePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.workExperience"
            @cancel="workExperiencePicker = false"
            @confirm="workExperienceConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.numbers"
          name="招聘人数"
          label="招聘人数"
          type="digit"
          :formatter="formatter"
          required
          placeholder="请输入招聘人数"
          :rules="[
            { required: true },
            {
              validator: validateNumber,
              message: '请输入大于0的整数',
              trigger: 'onBlur',
            },
          ]"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.positionTypeName"
          name="职位类型"
          label="职位类型"
          required
          readonly
          placeholder="请选择职位类型"
          :rules="[{ required: true }]"
          @click="positionTypePicker = true"
        >
          <template #button>
            <van-icon v-if="positionTypePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="positionTypePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.positionType"
            @cancel="positionTypePicker = false"
            @confirm="positionTypeConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.deptName"
          name="部门"
          label="部门"
          required
          placeholder="部门"
          readonly
          :rules="[{ required: true, message: '' }]"
          @click="deptShow"
        >
          <template #button>
            <van-icon v-if="deptPicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup
          v-model="deptPicker"
          round
          position="bottom"
          :style="{ height: '50%' }"
        >
          <div class="no-dept" @click="showDept = true">新增部门</div>
          <van-picker
            show-toolbar
            v-if="deptColumns.length"
            :columns="deptColumns"
            @cancel="deptPicker = false"
            @confirm="deptConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.deptName }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="positionForm.desc"
          name="职位描述"
          label="职位描述"
          required
          disabled
          readonly
          :rules="[{ required: true, message: '职位描述' }]"
        ></van-field>
        <quill-editors
          @ChangeText="
            (text) => {
              positionForm.desc = text;
            }
          "
          :showData="positionForm.desc"
          :title="true"
        />
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="positionForm.requirements"
          name="任职要求"
          label="任职要求"
          required
          disabled
          readonly
          :rules="[{ required: true, message: '任职要求' }]"
        />
        <quill-editors
          @ChangeText="
            (text) => {
              positionForm.requirements = text;
            }
          "
          :showData="positionForm.requirements"
          :title="true"
        />
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="positionForm.hardCondition"
          name="硬性要求"
          label="硬性要求"
          disabled
          readonly
        />
        <quill-editors
          @ChangeText="
            (text) => {
              positionForm.hardCondition = text;
            }
          "
          :showData="positionForm.hardCondition"
          :title="true"
        />
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="positionForm.excellentCondition"
          name="优先条件"
          label="优先条件"
          disabled
          readonly
        />
        <quill-editors
          @ChangeText="
            (text) => {
              positionForm.excellentCondition = text;
            }
          "
          :showData="positionForm.excellentCondition"
          :title="true"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.payModeDesc"
          name="薪水类型"
          label="薪水类型"
          required
          readonly
          placeholder="请选择薪水类型"
          :rules="[{ required: true }]"
          @click="payModePicker = true"
        >
          <template #button>
            <van-icon v-if="payModePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="payModePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="payModeColumns"
            @cancel="payModePicker = false"
            @confirm="payModeConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <div style="display: flex; align-items: center; overflow: hidden">
          <div
            style="display: flex; align-items: center"
            v-if="positionForm.payMode !== 'MONTHLY_SALARY'"
          >
            <div style="display: flex; align-items: center">
              <van-field
                v-model="positionForm.salaryMinimum"
                required
                placeholder="请输入年薪起薪"
                :disabled="this.negotiableChecked.length > 0"
                :rules="[{ required: true }]"
                @input="salaryLimit(positionForm.salaryMinimum)"
              />w
            </div>
            <span>至</span>
            <div style="display: flex; align-items: center">
              <van-field
                v-model="positionForm.salaryMaximun"
                required
                placeholder="请输入最高年薪"
                :disabled="this.negotiableChecked.length > 0"
                :rules="[{ required: true }]"
                @input="salaryLimit(positionForm.salaryMaximun)"
              />w
            </div>
          </div>
          <div style="display: flex; align-items: center" v-else>
            <div style="display: flex; align-items: center">
              <van-field
                v-model="positionForm.minimum"
                required
                :disabled="this.negotiableChecked.length > 0"
                placeholder="请输入月薪起薪"
                :rules="[{ required: true }]"
                @input="salaryLimit(positionForm.minimum)"
              />k
            </div>
            <span>至</span>
            <div style="display: flex; align-items: center">
              <van-field
                v-model="positionForm.maximum"
                required
                :disabled="this.negotiableChecked.length > 0"
                placeholder="请输入最高月薪"
                :rules="[{ required: true }]"
                @input="salaryLimit(positionForm.maximum)"
              />k
            </div>
          </div>
          <div style="width: 270px">
            <!-- <van-field name="switch" label="是否面议">
              <template #input>
                <van-switch v-model="negotiableChecked" size="20" />
              </template>
            </van-field>-->
            <van-field name="negotiableChecked" label>
              <template #input>
                <van-checkbox-group
                  v-model="negotiableChecked"
                  direction="horizontal"
                  @change="setSalary"
                >
                  <van-checkbox name="1" shape="square">是否面议</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
          </div>
        </div>
      </div>
      <div class="filed-box">
        <van-field
          name="labels"
          required
          label="推荐标签"
          v-if="dictionaries.jobLabel.length"
        >
          <template #input>
            <van-checkbox-group
              v-model="positionForm.labels"
              direction="horizontal"
            >
              <van-checkbox
                v-for="(item, i) in dictionaries.jobLabel"
                :key="i"
                :name="item.name"
                shape="square"
                >{{ item.name }}</van-checkbox
              >
            </van-checkbox-group>
          </template>
        </van-field>
      </div>

      <div class="filed-box">
        <van-field name="positionLabel" required label="职位标签">
          <template #input>
            <van-radio-group
              v-model="positionForm.positionLabel"
              direction="horizontal"
            >
              <van-radio
                v-for="(item, i) in dictionaries.positionTags"
                :key="i"
                :name="item.code"
                >{{ item.name }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
      </div>
      <div class="set-area-btn">
        <van-button round type="info" @click.prevent="setCompanyArea"
          >使用公司地区、地址</van-button
        >
        <van-button round type="info" @click.prevent="setCompanyMapArea"
          >地图选址</van-button
        >
      </div>
      <div class="filed-box">
        <van-field
          readonly
          clickable
          name="area"
          v-model="positionForm.cityDetailed"
          label="公司地区"
          required
          placeholder="请选择公司地区"
          :rules="[{ required: true }]"
          @click="showArea = true"
        >
          <template #button>
            <van-icon v-if="showArea" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="areaConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="positionForm.address"
          name="详细地址"
          label="详细地址"
          required
          placeholder="请填写详细地址"
          :rules="[{ required: true }]"
        />
      </div>

      <div class="btn-box">
        <van-button round block type="info">提交</van-button>
      </div>
    </van-form>
    <van-dialog
      v-model="mapShow"
      show-cancel-button
      title="选择地址"
    >
      <map-point-pick
				ref="mapSearch"
				@bindMapInfoToForm="bindMapInfoToForm"
				></map-point-pick>
    </van-dialog>
    <van-dialog
      v-model="showDept"
      show-cancel-button
      title="新增部门"
      :before-close="beforeClose"
    >
      <van-form @submit="createDept">
        <div class="list-box">
          <div class="filed-box">
            <van-field
              v-model="deptForm.deptName"
              name="部门"
              label="部门"
              required
              placeholder="请输入部门"
              :rules="[{ required: true, message: '' }]"
            />
          </div>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
  import areaList from '../../utils/area.json';
  export default {
    name: 'positionAddOrEdit',
    data() {
      return {
        title: '新增职位',
        dictionaries: {
          jobLabel: [],
          positionTags: [],
        },
        needDictionaries: [103, 104, 105, 160],
        descPopup: false,
        qualificationsTypePicker: false,
        workExperiencePicker: false,
        positionTypePicker: false,
        deptPicker: false,
        payModePicker: false,
        payModeColumns: [
          { code: 'MONTHLY_SALARY', name: '月薪' },
          { code: 'ANNUAL_SALARY', name: '年薪' },
        ],
        negotiableChecked: [],
        showArea: false,
        areaList: Object.freeze(areaList), // 省市数据
        positionForm: {
          id: null, // 职位id,创建的时候，默认填充0
          companyId: null, //需要发布职位的公司id,
          deptName: null, // 职位的部门名称,
          deptId: null, //需要发布职位的部门id,
          serialNo: null, //职位编号
          name: null, //职位名称
          numbers: 1, //招聘人数
          labels: [], //推荐类型
          unlimited: true, //是否有限制工作年限1.true要限制 2.flase不限制
          desc: null, //职位描述
          requirements: null, //任职要求
          creatorId: 0, //创建者id
          updatorId: 0, //最近修改该条记录的操作者id
          remarks: null, //职位备注
          provinceCode: null, //对应的省code
          cityCode: null, //对应的市code
          cityDetailed: null, //对应的区名
          locationCode: null, //对应的区code
          financingType: 0, //对应数据字典中融资情况code
          qualificationsName: '', //学历名称
          qualificationsType: 3, //学历要求
          workExperienceName: '', //经验名称
          workExperience: 2, //经验要求
          salaryRange: 1, //对应数据字典中薪资情况code
          createdTime: null, //创建时间，创建时为空
          updatedTime: null, //最近修改时间创建时为空
          address: null, //详细地址
          positionTypeName: '', //职位名称
          positionType: 1, //职位类型
          contractorId: null, // 联系人id
          effectiveStart: null, //职位有效开始时间
          effectiveEnd: null, //职位有效结束时间
          longitude: null, //经度
          latitude: null, //纬度
          minimum: 0, //最低 这里存入的是月薪
          maximum: 0, //最高 这里存入的是月薪
          salaryMinimum: 0, //最低 这里存入的是年薪(k)
          salaryMaximun: 0, //最高 这里存入的是年薪(k)
          rate: 0, //费率  如果要默认值:0
          payModeDesc: '月薪', //薪水类型名称
          payMode: 'MONTHLY_SALARY', //薪水类型
          // welfare: '',//福利待遇
          interviewFlow: '', //面试流程
          calculationMethod: '', //佣金计算方式
          salaryComposition: '', //年薪组成
          publishChannel: 'JOBSEEKER', //发布平台
          settlementMode: 'ONCE', //结算方式  如果要默认值:'ONCE'
          firstProportion: 100, //一次性付费  如果要默认值:100
          secondProportion: 0, //分两次付费的第一次付费 如果要默认值:0
          twoPaySecond: '', //分两次付费的第二次付费
          expectCost: 0, //预计最低佣金
          maxExpectCost: 0, //预计最高佣金
          positionLabel: '', // 职位标签
          positionExpiryDate: '', // 有效期内
          hardCondition: null, //硬性要求(非必填)
          excellentCondition: null, //优先条件(非必填)
        },
        deptColumns: [],
        isNewDept: false,
        showDept: false,
        deptForm: {
          companyId: null,
          deptName: null,
        },
        userInfo: null,
        mapShow: false, //地图弹窗
      };
    },
    async mounted() {
      let { query } = this.$route;
      if (query.id) {
        this.title = '编辑职位';
        this.positionForm.id = query.id;
      } else if (query.name) {
        this.positionForm.nameCn = query.name;
      }
      let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
      if (back.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(back.data));
        this.userInfo = back.data;
      }
      let dictionaries = localStorage.getItem('dictionaries');
      if (dictionaries) {
        dictionaries = JSON.parse(dictionaries);
        dictionaries.forEach((el) => {
          if (this.needDictionaries.includes(el.code)) {
            this.dictionaries[el.type] = el.children;
          }
        });
      }
      this.getPositionTags();
      this.getDeptList(this.userInfo.selfhelpCompanyApproveVO.companyId);
    },
    methods: {
      //地图选择开启
      setCompanyMapArea() {
        this.mapShow = true
      },
      //获取坐标
      bindMapInfoToForm(data){
        this.positionForm.address = `${data.address}【${data.name}】`
				this.positionForm.latitude = data.location.lat
				this.positionForm.longitude = data.location.lng
      },
      formatter(value) {
        return Number(value);
      },
      // 正整数
      validateNumber(value) {
        if (value == 0) {
          return false;
        } else {
          return true;
        }
      },
      setCompanyArea() {
        this.positionForm.address = this.userInfo.companyVO.address;
        let areaId = this.userInfo.companyVO.areaId.toString();
        let areaArr = [
          areaId.slice(0, 2) + '0000',
          areaId.slice(0, 4) + '00',
          areaId,
        ];
        this.positionForm.provinceCode = areaArr[0];
        this.positionForm.cityCode = areaArr[1];
        this.positionForm.locationCode = areaArr[2];
        this.positionForm.cityDetailed =
          areaList.province_list[areaArr[0]] +
          '/' +
          areaList.city_list[areaArr[1]] +
          '/' +
          areaList.county_list[areaArr[2]];
        this.positionForm.longitude = this.userInfo.companyVO.longitude;
        this.positionForm.latitude = this.userInfo.companyVO.latitude;
      },
      getPositionDetail(id) {
        this.$http
          .get('/selfhelpOfficialAccounts/positionSelfhelp/' + id)
          .then((res) => {
            if (res.code === 200) {
              Object.assign(this.positionForm, res.data);
              if (this.positionForm.cityDetailed)
                this.positionForm.cityDetailed =
                  this.positionForm.cityDetailed.replace(/,/g, '/');
              if (this.positionForm.labels) {
                let self = this;
                let arr = [];
                this.dictionaries.jobLabel.forEach((el) => {
                  if (self.positionForm.labels.includes(el.code.toString())) {
                    arr.push(el.name);
                  }
                });
                this.positionForm.labels = arr;
              }
              if (
                this.positionForm.salaryMinimum == 0 &&
                this.positionForm.salaryMaximun == 0 &&
                this.positionForm.payMode == 'MONTHLY_SALARY'
              ) {
                this.negotiableChecked = ['1'];
              }
              if (
                this.positionForm.minimum == 0 &&
                this.positionForm.maximum == 0 &&
                this.positionForm.payMode == 'ANNUAL_SALARY'
              ) {
                this.negotiableChecked = ['1'];
              }
            }
          });
      },
      getPositionTags() {
        this.$http
          .get('/selfhelpOfficialAccounts/label/findLabelNameList', {
            type: 3,
            pageSize: 200,
          })
          .then((res) => {
            if (res.code === 200) {
              this.dictionaries.positionTags = res.data.map((item) => {
                return {
                  code: item.code,
                  name: item.value,
                };
              });
              if (this.positionForm.id)
                this.getPositionDetail(this.positionForm.id);
            }
          });
      },
      onClickLeft() {
        this.$router.go(-1);
      },
      deptShow() {
        if (this.userInfo.selfhelpCompanyApproveVO.companyId) {
          this.deptPicker = true;
        } else {
          this.$toast('请先选择公司');
        }
      },
      getDeptList(id) {
        this.$http
          .get('/selfhelpOfficialAccounts/companyDept', { companyId: id })
          .then((res) => {
            if (res.code === 200) {
              this.deptColumns = res.data || [];
              // this.isNewDept = res.data.length === 0;
            }
          });
      },
      createDept(done) {
        this.deptForm.companyId =
          this.userInfo.selfhelpCompanyApproveVO.companyId;
        if (!this.deptForm.deptName) {
          this.$toast('请输入部门');
          return;
        }
        this.$http
          .post('/selfhelpOfficialAccounts/companyDept', this.deptForm)
          .then((res) => {
            if (res.code === 200) {
              this.$toast('新增部门成功');

              this.deptColumns = this.deptColumns.concat(res.data);
              done();
              this.showDept = false;
            } else {
              this.$toast(res.message || '新增部门失败');
            }
          });
      },
      beforeClose(action, done) {
        if (action === 'confirm') {
          if (!this.deptForm.deptName) {
            this.$toast('请输入部门');
            done(false);
            return;
          }
          this.createDept(done);
        } else {
          done();
        }
      },
      qualificationsTypeConfirm(data) {
        this.positionForm.qualificationsName = data.name;
        this.positionForm.qualificationsType = data.code;
        this.qualificationsTypePicker = false;
      },
      workExperienceConfirm(data) {
        this.positionForm.workExperienceName = data.name;
        this.positionForm.workExperience = data.code;
        this.workExperiencePicker = false;
      },
      positionTypeConfirm(data) {
        this.positionForm.positionTypeName = data.name;
        this.positionForm.positionTypeType = data.code;
        this.positionTypePicker = false;
      },
      deptConfirm(data) {
        this.positionForm.deptName = data.deptName;
        this.positionForm.deptId = data.id;
        this.deptPicker = false;
      },
      payModeConfirm(data) {
        this.positionForm.payModeDesc = data.name;
        this.positionForm.payMode = data.code;
        this.payModePicker = false;
        this.handleChange(data.code);
      },
      // 保留两位小数
      floatTwoDigits(num) {
        //无法整除只取两位数
        if (num) num = parseInt(num * 100) / 100;
        //是NaN,变成0
        if (num == 'NaN') num = 0;
        return num;
      },
      // 选中面议
      setSalary() {
        if (this.negotiableChecked.length) {
          this.positionForm.salaryMinimum = 0;
          this.positionForm.salaryMaximun = 0;
          this.positionForm.minimum = 0;
          this.positionForm.maximum = 0;
        }
      },
      // 根据薪水类型,指定薪水单位
      handleChange(value) {
        if (!value || value == 'MONTHLY_SALARY') {
          // 月薪计算逻辑
          this.positionForm.minimum = this.floatTwoDigits(
            (((this.positionForm.salaryMinimum * 1000) / 12) * 10) / 1000
          ); //*1000再/1000为了解决x.9999问题
          this.positionForm.maximum = this.floatTwoDigits(
            (((this.positionForm.salaryMaximun * 1000) / 12) * 10) / 1000
          );
        } else if (value == 'ANNUAL_SALARY') {
          //年薪计算逻辑
          this.positionForm.salaryMinimum = this.floatTwoDigits(
            (this.positionForm.minimum * 1000 * 12) / 10 / 1000
          );
          this.positionForm.salaryMaximun = this.floatTwoDigits(
            (this.positionForm.maximum * 1000 * 12) / 10 / 1000
          );
        }
      },
      salaryLimit() {
        this.positionForm.minimum = this.floatTwoDigits(
          this.positionForm.minimum
        );
        if (this.positionForm.minimum > 999) {
          this.positionForm.minimum = 0;
          this.$message.warning('薪水不能大于百位数！');
        }
        this.positionForm.maximum = this.floatTwoDigits(
          this.positionForm.maximum
        );
        if (this.positionForm.maximum > 999) {
          this.positionForm.maximum = 0;
          this.$message.warning('薪水不能大于百位数！');
        }
        this.positionForm.salaryMinimum = this.floatTwoDigits(
          this.positionForm.salaryMinimum
        );
        if (this.positionForm.salaryMinimum > 999) {
          this.positionForm.salaryMinimum = 0;
          this.$message.warning('薪水不能大于百位数！');
        }
        this.positionForm.salaryMaximun = this.floatTwoDigits(
          this.positionForm.salaryMaximun
        );
        if (this.positionForm.salaryMaximun > 999) {
          this.positionForm.salaryMaximun = 0;
          this.$message.warning('薪水不能大于百位数！');
        }
      },
      dictionariesBackName(data, id) {
        let name = '';
        data.forEach((el) => {
          if (el.code === id) {
            name = el.name;
          }
        });
        return name;
      },
      async getProvinces() {
        let res = await this.$http.get(
          '/selfhelpOfficialAccounts/area/provinces'
        );
        return res;
      },
      async getAreaChildren(id) {
        let res = await this.$http.get(
          '/selfhelpOfficialAccounts/area/parent/' + id
        );
        return res;
      },
      async areaPopupOpen() {
        if (this.areaList[0].values.length === 0) {
          this.loading = true;
          let res = await this.getProvinces();
          if (res.code === 200) {
            this.areaList[0].values = res.data;
            let res2 = await this.getAreaChildren(res.data[0].id);
            this.areaList[1].values = res2.data[0].children;
            if (res2.code === 200) {
              let res3 = await this.getAreaChildren(
                res2.data[0].children[0].id
              );
              if (res3.code === 200) {
                this.areaList[2].values = res3.data[0].children;
                this.loading = false;
                this.showArea = true;
              }
            }
          }
        } else {
          this.showArea = true;
        }
      },
      async areaChange(picker, values) {
        console.log(picker, values);
        if (this.areaList[0].values.length === 0) {
          let res = await this.getProvinces();
          if (res.code === 200) {
            console.log(res);
          }
        }
        if (this.areaList[1].values.length === 0) {
          let res = await this.getAreaChildren();
          if (res.code === 200) {
            console.log(res);
          }
        }
      },
      areaConfirm(values) {
        this.positionForm.cityDetailed = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
        let areaArr = values.filter((item) => !!item).map((item) => item.code);
        this.positionForm.provinceCode = areaArr[0];
        this.positionForm.cityCode = areaArr[1];
        this.positionForm.locationCode = areaArr[2];
        this.showArea = false;
      },
      onSubmit() {
        this.positionForm.companyId = this.userInfo.companyVO.id;
        let arr = [];
        this.dictionaries.jobLabel.forEach((el) => {
          if (this.positionForm.labels.includes(el.name)) {
            arr.push(el.code);
          }
        });
        let copy = Object.assign({}, this.positionForm);
        copy.labels = arr;
        if (copy.id) {
          this.$dialog
            .confirm({
              message: '确定要修改么？',
            })
            .then(() => {
              this.$http
                .put('/selfhelpOfficialAccounts/positionSelfhelp', copy)
                .then((res) => {
                  if (res.code === 200) {
                    this.$toast('职位更新成功');
                    this.$router.push('/positionMgt/index');
                  } else {
                    this.$toast(res.message || '职位更新成功', 3);
                  }
                });
            });
        } else {
          this.$http
            .post('/selfhelpOfficialAccounts/positionSelfhelp', copy)
            .then((res) => {
              if (res.code === 200) {
                this.$toast('职位新增成功,正在为你跳转列表');
                this.$router.push('/positionMgt/index');
              } else {
                this.$toast(res.message || '职位新增成功', 3);
              }
            });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .position-add-edit-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .form {
      flex: 1;
      padding: 0 15px;
      overflow-y: auto;
    }
    .list-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 8px 5px 0;
      > p {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebedf0;
      > .van-cell {
        flex: 1;
      }
      > .van-cell::after {
        border-bottom: none;
      }
      img {
        width: 77px;
        height: 23px;
      }
      ::v-deep .van-field__label span {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      ::v-deep .van-field--disabled .van-field__label {
        color: #646566;
      }
      ::v-deep .van-field__value input {
        text-align: right;
      }
      ::v-deep .van-field__control--custom {
        justify-content: right;
      }
      ::v-deep .van-field--min-height .van-field__control {
        text-align: right;
      }
      .no-dept {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 16px;
        overflow: hidden;
        font-size: 14px;
        line-height: 24px;
        background-color: #fff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #206cfe;
        justify-content: center;
      }
    }
    .set-area-btn {
      padding-top: 16px;
      display: flex;
      justify-content: space-between;
      .van-button {
        height: 30px;
      }
    }
    .editors-box {
      display: initial;
      ::v-deep .van-field__body input {
        display: none;
      }
    }
    .van-search .van-cell {
      padding: 5px 8px 5px 0;
      ::v-deep .van-field__value input {
        text-align: initial;
      }
    }
    ::v-deep .van-field__error-message {
      text-align: right;
      font-size: 14px;
    }
    .btn-box {
      margin: 30px 0;
      padding: 0 46px;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
